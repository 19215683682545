import React from 'react'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SettingsIcon from '@material-ui/icons/Settings';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import PostAddIcon from '@material-ui/icons/PostAdd';
import FeedbackIcon from '@material-ui/icons/Feedback';
import PublishIcon from '@material-ui/icons/Publish';
import TimelineIcon from '@material-ui/icons/Timeline'
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import SnowLogin from './SnowLogin';


import HomeIcon from '@material-ui/icons/Home';



//require('dotenv').config();

const drawerWidth = 240;

    

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));



const SnowNavigation = ({checkIsLoggedIn, isLoggedIn, showAlert}) => {

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogin = (e) => {
      console.log('change the login state, calling another callback query.')
      console.log('the response: ' + JSON.stringify(e))
      //get permission from login response and set values to enable/disable menu items

      //callback function to update the parent login state
      checkIsLoggedIn(e);

  }

  

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <div className="avatar">
        <SnowLogin showAlert={showAlert} isLoggedIn={isLoggedIn} checkIsLoggedIn={checkIsLoggedIn} />
          
        </div>
        <Toolbar>
        {isLoggedIn ? 
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          :
          <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        }
          <div className="logoBox"><a href={process.env.REACT_APP_DASH_URL}><img alt="Snow Machine" src="/snowmachine-logo-blue-white.png" className="headerLogo" /></a><br /></div>

        </Toolbar>
        
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
            <ListItem className="navListItem">
                  <ListItemIcon><HomeIcon /></ListItemIcon>
                  <ListItemText><a className="nav-item-child" href={process.env.REACT_APP_DASH_URL}>Dashboard</a></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className={(window.location.host.indexOf('.') > -1 && (window.location.host.split('.')[0] === 'dam' || window.location.host.split('.')[0] === 'dam-stg') && window.location.pathname === '/') || (window.location.host === 'localhost:3000' && window.location.pathname === '/') ? "navListItem navListItemFocus" : "navListItem" }>
              <ListItemIcon><AddAPhotoIcon /></ListItemIcon>
              <ListItemText><a className="nav-item-child" href={process.env.REACT_APP_DAM_URL}>Upload Images</a></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className={(window.location.host.indexOf('.') > -1 && (window.location.host.split('.')[0] === 'dam' || window.location.host.split('.')[0] === 'dam-stg') && window.location.pathname === '/grid') || (window.location.host === 'localhost:3000' && window.location.pathname === '/grid') ? "navListItem navListItemFocus" : "navListItem" }>
              <ListItemIcon><ImageSearchIcon /></ListItemIcon>
              <ListItemText><a className="nav-item-child" href={process.env.REACT_APP_DAM_URL + '/grid'}>Search Images</a></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className="navListItem">
              <ListItemIcon ><PostAddIcon   /></ListItemIcon>
              <ListItemText><a className="nav-item-child" href={process.env.REACT_APP_PIM_URL + '/add'}>Add Products</a></ListItemText>
            </ListItem>
            <Divider />


            <ListItem className="navListItem">
            <ListItemIcon ><CloudUploadIcon /></ListItemIcon>
            <ListItemText><a className="nav-item-child" href={process.env.REACT_APP_PIM_URL + '/import'}>Import Products</a></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className="navListItem">
              <ListItemIcon><EditIcon /></ListItemIcon>
              <ListItemText><a className="nav-item-child" href={process.env.REACT_APP_PIM_URL + '/edit'}>Search/Edit Products</a></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className="navListItem">
              <ListItemIcon> <PublishIcon /></ListItemIcon>
              <ListItemText><a className="nav-item-child" href={process.env.REACT_APP_AI_URL}>Publish Products</a></ListItemText>
            </ListItem>
            <Divider /> 
            <ListItem className="navListItem">
              <ListItemIcon> <TimelineIcon /></ListItemIcon>
              <ListItemText><a className="nav-item-child" href={process.env.REACT_APP_AI_PUBLISHING}>Publishing History</a></ListItemText>
            </ListItem>
            <Divider /> 
            
            <ListItem className="navListItem">
              <ListItemIcon> <SettingsInputComponentIcon /></ListItemIcon>
              <ListItemText><a className="nav-item-child" href={process.env.REACT_APP_AI_CHANNELS}>Projects and Channels</a></ListItemText>
            </ListItem>
            <Divider /> 
        </List>
        <ListItem className="navListItem bottom">
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText><a className="nav-item-child" href={process.env.REACT_APP_ADMIN_URL}>Settings</a></ListItemText>
        </ListItem>
        <ListItem className="navListItem">
              <ListItemIcon><FeedbackIcon /></ListItemIcon>
              <ListItemText><a className="nav-item-child" rel="noopener noreferrer" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSebisNj67NG3a4Fgn_3aocZoNT49k4YOcuAXkfVZerdyDJ_PA/formResponse">Submit Feedback</a></ListItemText>
        </ListItem>
        <Divider />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className="clearBoth"></div>
        
      </main>
    </div>
  );
}

export default SnowNavigation;