import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useGoogleLogin } from '@react-oauth/google'


const SnowLogin = ({showAlert, isLoggedIn, checkIsLoggedIn}) => {

  const [isAuthentic, setIsAuthentic] = useState('none')
  const [avatarUrl, setAvatarUrl] = useState('')
  const [isMyAccount, setIsMyAccount] = useState(false)
  const cookies = new Cookies()


  const loginV2 = useGoogleLogin({
    onSuccess: codeResponse => finishLoginV2(codeResponse),
    flow: 'auth-code',
    scope: 'https://spreadsheets.google.com/feeds/'
  })

  const finishLoginV2 = async (codeResponse) => {
    var options = {}
    options = {
      'token': codeResponse.code
    }
    
    var siteUrl = ''; //set siteUrl if declared (for local testing)
    if(process.env.REACT_APP_API_HOST){
        siteUrl = process.env.REACT_APP_API_HOST;
    }
    //call verifySmUser to either create a session, or validate a session and its expiration
    await axios.post(siteUrl + '/api/login',options, { withCredentials: true })
      .then(result => {
        //confirm this session has not expired
        let currentTimestamp = Date.now()
        if(currentTimestamp > result.data.exp){
          //token is NOT expired
          setIsAuthentic(true)
          setAvatarUrl(result.data.avt)
          cookies.set('smAvt', decodeURI(result.data.avt), { path: '/', maxAge: 432000, domain: process.env.REACT_APP_COOKIE_DOMAIN_STRIPPED })
          //setIsAuthentic(true)
          refreshCredentials(codeResponse)
        }

      }).catch((error) => {
        showAlert('error', "An unknown error has occured.  Please refresh and try again.  If you continue to receive this error, please share this message with the dev team. -> " + JSON.stringify(error), '')
        logout() 
      })
      
  }

  
  /*function is called to verify a user and create a soft-login on the UX if the SM token is nearing it's expiration   */
  const refreshCredentials = async (e) => {
    //add the token to the request.  if this is being called to login the user, and no session is available, the token will be used to authenticate and start a session
    //otherwise, this token will be ignored in the request, and the session will be used.
    var options = {}
    var siteUrl = ''; //set siteUrl if declared (for local testing)
    if(process.env.REACT_APP_API_HOST){
        siteUrl = process.env.REACT_APP_API_HOST;
    }
    //call verifySmUser to either create a session, or validate a session and its expiration
    await axios.post(siteUrl + '/api/login',options, { withCredentials: true })
      .then(result => {
          if(result.data.status === 200){
              //verify that the session expiration returned in the response has not expired. 
              if(result.data.exp < (Math.round(Date.now()/1000))){
                  //the session is within 5 days of real expiration.  Show the popup for the user to login again.
                  setIsAuthentic(true)
                  loginV2()
                  showAlert('info', 'Your Snow Machine session has expired.  If your are not automatically logged in, please click the login button.', 5000, '')
              }else{
                  /* if this user is already authentic, we don't want to call checkIsLoggedIn.  
                     They passed the validation as a logged in user so we don't need to do more.
                     But if this is a logged out user now being authenticated, continue with checkedIsLoggedIn :)
                  */
                  if(isAuthentic !== true){
                    let softRevalidate = false
                    if(e.softRevalidate){
                      //this is a reauth of an existing session on tab focus
                      softRevalidate = true
                    }
                    /* SET PERMISSIONS - and moved this.props.handleLogin below with a new parameter */
                    let myPermissions = '{"none": "none"}'
                    if(result.data.permissions && result.data.permissions[0]){
                      if(result.data.permissions[0].permissions){
                        myPermissions = JSON.parse(result.data.permissions[0].permissions)
                      }
                    }
                    /* end PERMISSIONS */
                    //callback to set login state
                    checkIsLoggedIn({isAuthentic: true, permissions: myPermissions, softRevalidate: softRevalidate, userId: result.data.id, userEmail: result.data.user})
                    //set the Avt cookie
                    setAvatarUrl(cookies.get('smAvt'))
                  }
                  //set the isAuthentic state to true to change the button state
                  setIsAuthentic(true)
              }  
          }else if(result.data.status === 403){
            //an error occured, but the status represents a request to initiate an autologin.  show the login window.
            loginV2()
            showAlert('info', 'Your Snow Machine session will soon expire.  We are triggering an SSO window to login again.', 5000, '')
          }else{
            //a different error status was returned. logout user and show the message in result.data.error to the user
            let erroMessage = 'You do not have a Snow Machine Account.  Please contact your manager to gain access, or try to login again with your Snow Commerce account. '
            if(result.data.error){
              erroMessage = result.data.error
            }
            //if this was a soft revalidation request, don't show an alert.  Otherwise, alert the user.
            if(!e.softRevalidate){
              showAlert('error', erroMessage, 5000, '')
            }
            //confirm the user is entirely logged out and the session is destroyed
            logout() 
            //callback to App.js
            checkIsLoggedIn({isAuthentic: false, permissions: '{"none": "none"}', softRevalidate: true})
            
          }
    })     
   
  }

  /* destroys all cookie/session related data and resets UX to logged out state */
  const logout = async (e) => {
    console.log('clicked')
      //destroy session in backend
      var options = {}
      var siteUrl = ''; //set siteUrl if declared (for local testing)
      if(process.env.REACT_APP_API_HOST){
          siteUrl = process.env.REACT_APP_API_HOST;
      }
      await axios.post(siteUrl + '/api/logoutSmUser',options, { withCredentials: true })
      //await axios.post('/api/logoutSmUser',options)
      //set state
      setIsAuthentic('none')
      setIsMyAccount(false)
      setAvatarUrl('')
      //remove cookies
      cookies.remove('smAvt', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN_STRIPPED })
      cookies.remove('smsession', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN_STRIPPED })
      //callback function to send login state up the chain
      checkIsLoggedIn({isAuthentic: false, softRevalidate: true})
  }

  /* open the logout menu */
  const openMenu = () => {
    console.log('clicked')
    if(isMyAccount === true){
      setIsMyAccount(false)
    }else{
      setIsMyAccount(true)
    }
  }

  useEffect(() => {
   /* add a listener to check for authentication when the browser tab is set to focus.  we should do this every time the focus is set to this 
    browser tab.  1, to show the login state if a user loged in on another tab, and 2, to show the logout state if the user logged out on another tab. */
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        //revalidate the user in case they had logged in or out from another tab
        let obj = {}
        obj['credential'] = ""
        //set a preference for a soft revalidation.  
        obj['softRevalidate'] = true  
        //call the refresh function
        refreshCredentials(obj)
      } 
    }, false)
  }, [isAuthentic])


  //on load 
  useEffect(() => {
      //v4.7.1 - if cookie exists on page load, call refreshCredentials to confirm it's still valid.
      if(cookies.get('smAvt')){
        //call function to verify token expiration and get user permissions and set to local
        let obj = {}
        //no need for credentials.  they will come from session on refresh
        obj['credential'] = ""
        //set a preference for a soft revalidation.  
        obj['softRevalidate'] = true  
        refreshCredentials(obj)
      }
  }, [])

  return (
    <div>

      

      

      {  isAuthentic === true ?
          <div>
            <div>{/* display a avatar icon and logout button mini menu */}
              {isMyAccount === true ? <div className="accountMenuWrapper" onClick={openMenu} ></div> : null }
              {avatarUrl ? 
                <img alt="Logout" className="avatarIcon" onClick={openMenu}  src={avatarUrl} />
              : null }
              {isMyAccount ? <div><div className="accountMenu"><div onClick={logout}>Log Out</div></div></div> : null }
            </div>
          </div>
       :
          <button className="smLoginButton" onClick={() => loginV2()}>Log In with Google</button>
       }
      
    </div>
  )
}
      
export default SnowLogin