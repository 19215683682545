//import React from 'react';
import React, { Fragment } from 'react'
import './App.css';
import FileUpload from './FileUpload';
import SnowDamGrid from './SnowDamGrid';
import SnowNavigation from './SnowNavigation';
import { BrowserRouter as Router , Route} from 'react-router-dom';
import CopyrightIcon from '@material-ui/icons/Copyright';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';

import { GoogleOAuthProvider } from '@react-oauth/google'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'





const App = () => {

  
  const [isLoading, setIsLoading] = React.useState(false)
  const [isLoggedIn, setIsLoggedIn] = React.useState('')
  const [rowTotal, setRowTotal] = React.useState('...');
  const [genMerchDesignId, setGenMerchDesignId] = React.useState('')
  const [previewImage,setPreviewImage] = React.useState('')
  const [permissions, setPermissions] = React.useState({})
  const [smUser, setSmUser] = React.useState('')

 
  const handleImages = (e) => {
      //this function is no longer being used.
      console.log('handle images: ' + JSON.stringify(e))
     
  }
  //update the returned row totals (callback function)
  const handleCountUpdate = (e) => {
      setRowTotal(e.value)
  }
 
  const checkIsLoggedIn = (e) => {
    //callback function is called by snowNav, which is called by SnowLogin, to return google token to parent app
    //and verify the google token received.
    //check value of isLogined from googleAuth and set to state
    if(e.isAuthentic === true){
        //setIsLoading(false)
        /* NEW PERMISSIONS feature */
        if(e.permissions){
          if(e.userEmail && e.userEmail.indexOf('@') > -1){
            let myEmail = e.userEmail.split('@')[0]
            setSmUser(myEmail)
          }else{
            setSmUser(e.userEmail)
          }
            
            let myPermissions = Object.entries(e.permissions)
            if(myPermissions[1][1]){
              setPermissions(myPermissions[1][1])
            }
        }
        /* end NEW PERMISSIONS feature */
        setIsLoggedIn(true)
    }else if(e.isAuthentic === false){
        setIsLoggedIn(false)
        setIsLoading(false)
    }else{
        setIsLoggedIn(false)
        setIsLoading(false)
    }  
}





  const handleLoader = (e) => {
    setIsLoading(e)
  }

   /* function shared as a prop to all components to send toast notifications to the user */
   const showAlert = (type, message, time, url) => {
    if(type === 'success'){
      toast.success(message, {
          position: "top-center", autoClose: time, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      })
    }else if(type === 'error'){
      toast.error(message, {
          position: "top-center", autoClose: time, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      })
    }else if(type === 'warning'){
      toast.warning(message, {
          position: "top-center", autoClose: time, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      })
    }else if(type === 'info'){
      toast.info(message, {
          position: "top-center", autoClose: time, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined
      })
    } 
}


  

  

  return (
    <div >
      <div className="navigationBar"></div>
      { isLoading ? <div className="snowflake"><img alt="Snow Commerce" className="App-logo" src="/snow-mark.png" /></div> :  
                        null 
                    }
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH }>
                <SnowNavigation showAlert={showAlert} isLoggedIn={isLoggedIn} checkIsLoggedIn={checkIsLoggedIn}  />
          </GoogleOAuthProvider>
          <ToastContainer className="toastie" />
      
         
          {isLoggedIn ?
            <div>
              <Router>
                    <Route exact path="/ez">
                          <iFrame src="https://snowcommerce.retool.com/apps/Snow%20Machine%20Stage/Snow%20Machine%203.0.1%20(embed)" className="tempEz"></iFrame>
                    </Route>
                    <Route exact path={["/", "/upload"]} >
                    <div style={{minHeight: '600px'}} >
                      {isLoggedIn === true ?
                        <FileUpload showAlert={showAlert} smUser={smUser} handleImages={handleImages} />
                      : null }
                    </div>
                    </Route>
                    <Route exact path="/grid">
                    {isLoggedIn === true ?
                        <Fragment>
                          
                          <div className="content">
                                <label>DAM - Image/File Search</label><ImageSearchIcon className="titleIcon" />
                                <div className="leftBlock"><span className="grey">Total Records: </span><span>{rowTotal && rowTotal !== '...' ? Number(rowTotal).toLocaleString("en-US") : rowTotal} </span></div>   
                          </div>
                         

                          
                              <div className="content">
                                  {permissions ? 
                                    <SnowDamGrid smUser={smUser} showAlert={showAlert} permissions={permissions} gridType="grid" handleCountUpdate={handleCountUpdate}  />
                                  : null }
                              </div>
                          
                        </Fragment>
                      : null }
                    </Route>
                    
              </Router>
            </div>
          : null }

          {isLoggedIn === false  ?
            <div style={{width: '100%', textAlign: 'center'}}>
              <div className="loginMessage">
                <div>Welcome to Snowmachine - by Snow Commerce.<br /><br />To continue, please login with your G-Suite account above.<br /><br /></div>
              </div>
            </div>
          : null }
          
      <div className="myFooter">
            <div className="poweredByTitle">powered by</div>
            <img alt="Powered By Snow Commerce" src="/snow-logo.png" className="poweredBy" />
            <div className="poweredByCopy"><CopyrightIcon style={{ fontSize: '14px' }} />{(new Date().getFullYear())} - <a href="https://www.snowcommerce.com" style={{color: '#013878'}} target="_blank">www.snowcommerce.com</a></div>
        </div>
     
    </div>
  );
}

export default App;
