import React, { Fragment, useEffect, useState, useMemo } from 'react'
import { useTable, useSortBy, useFilters, usePagination, useRowSelect } from 'react-table';
import { Input } from "reactstrap"
import axios from 'axios';
import useDebounce from './debounce';
import Modal from 'react-modal';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BurstModeIcon from '@material-ui/icons/BurstMode'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import VideoPlayer from 'simple-react-video-thumbnail'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircle from '@material-ui/icons/CheckCircle'


import LockIcon from '@material-ui/icons/Lock'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";







// bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')


const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)


const TableContainer = ({showAlert, handleCountUpdate, vendors, gridType, designId, permissions, smUser}) => {
  
    
    const [curColumn, setCurColumn] = useState('');
    const [qcolumn, setQcolumn] = useState([]); //all columns being filtered
    const [qvalue, setQvalue] = useState([]); //all values being filtered
    const [rowTotal, setRowTotal] = useState('...');
    const [rowTotalFilter, setRowTotalFilter] = useState('...');
    const [init, setInit] = useState(true);
    const [data, setData] = useState([])
    const [query, setQuery] = useState('');
    const [isSearching, setIsSearching] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const debouncedSearchTerm = useDebounce(query, 500);
    const [modalIsOpen,setIsOpen] = useState(false);
    const [previewImage,setPreviewImage] = useState('');
    const [previewIndex, setPreviewIndex] = useState('');
    const [copySuccess, setCopySuccess] = useState('');
    const [genMerchDesignId, setGenMerchDesignId] = useState('')

    const [startDate, setStartDate] = useState(''); //used for filter by date 
    const [confirmationOpen, setConfirmationOpen] = useState(false)
        


    const selectImage = (e) => {
      //this should callback handleCountUpdate function, which will actually return the URL and design id
      //so the states can be updated in the snowmerchgenerator component
      //not really being used anymore i don't think since we moved the sonwMerchGen module to the app.js
      let myObj = {url: e.target.src, image_design_id: e.target.dataset.id }
      handleCountUpdate(myObj)
    }
    /* open the modal window to preview image */
    const openModal = (e) => {
      //set the loader, set state values for each form element, and then hide the loader
      setIsLoading(true)
      setGenMerchDesignId(e.target.dataset.id)
      setIsOpen(true)
      if(e.target.src){
        setPreviewImage(e.target.src.replace(process.env.REACT_APP_CACHE_URL, 'https://' + process.env.REACT_APP_BUCKET + '.s3.us-east-2.amazonaws.com/').replace('?auto=compress&fm=webp&h=300',''))
        // set state to the index of this particular cell so the data can be parsed and image_datetime, image_name, image_tags can be accessed
        setPreviewIndex(e.target.dataset.index)
      }
      if(e.target.dataset.img){
          setPreviewImage(e.target.dataset.img.replace(process.env.REACT_APP_CACHE_URL, 'https://' + process.env.REACT_APP_BUCKET + '.s3.us-east-2.amazonaws.com/').replace('?auto=compress&fm=webp&h=300',''))
      }
    }

    const confirmDelete = async (e) => {
      setConfirmationOpen(true)
    }

    const deleteImage = async (e) =>{
      // delete image after verifying it is not used as a merch or vendor image
      setIsLoading(true)
      setConfirmationOpen(false)
      let fileName = previewImage.split('.com/')[1]
      var siteUrl = ''; //set siteUrl if declared (for local testing)
      if(process.env.REACT_APP_API_HOST){
          siteUrl = process.env.REACT_APP_API_HOST;
      }
      let moreOptions = {
        'fileName': fileName,
        'previewImage': previewImage
      }
      
      let now = +new Date()
      let createdAt = +new Date(Date.parse(data[previewIndex].image_datetime.toString()))
      var twoHours = 2 * 60 * 60 * 1000
      if ((now - createdAt) > twoHours){
        var result = await axios.post(siteUrl + '/api/deleteImage', moreOptions, { withCredentials: true })
        closeModal()
        if(result && result.data && result.data.success){
          showAlert("success", "The image has been deleted!", 5000, '')
          setQuery('')
        } else if( result && result.data && result.data.error){
          showAlert("error", String(result.data.error), 5000, '')
        }
      } else {
        showAlert("warning", "The image was uploaded under 2 hours ago, and must clear the streaming buffer before it can be deleted. Please wait and try again later.", 5000, '')
        setIsLoading(false)
      }
      setIsLoading(false)
    }


    /* close modal image preview window  */
    function closeModal(){
     
      setIsOpen(false);
      setIsLoading(false)
      setPreviewImage('')
      

    }

    /* clear the filter, or clear the filter an filter by designId */
    const setDesignIdFilter = (e) => {
        let tempId = ''
        if(e.target.dataset.design){
            tempId = e.target.dataset.design
        }

        //set the value of the design id input field
        var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(document.getElementById('image_design_id_input'), tempId);
        //clear the values of all other input fields
        nativeInputValueSetter.call(document.getElementById('image_file_name_input'), '');
        nativeInputValueSetter.call(document.getElementById('image_name_input'), '');
        nativeInputValueSetter.call(document.getElementById('image_tags_input'), '');
        nativeInputValueSetter.call(document.getElementById('image_datetime_input'), '');            
        //dispatch searh event on design id field
        var ev2 = new Event('input', { bubbles: true});
        //first trigger events for other empty fields to clear them from the react filter
        document.getElementById('image_file_name_input').dispatchEvent(ev2);
        document.getElementById('image_name_input').dispatchEvent(ev2);
        document.getElementById('image_tags_input').dispatchEvent(ev2);
        document.getElementById('image_datetime_input').dispatchEvent(ev2);
        setStartDate(null)
        //final event trigger to searh for design id only
        document.getElementById('image_design_id_input').dispatchEvent(ev2);
        
    }

    const setTagsFilter = (e) => {
    
      let imageUser = String(smUser)
      //get the user id from state
          //set the value of the design id input field
          var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
          nativeInputValueSetter.call(document.getElementById('image_design_id_input'), '');
          //clear the values of all other input fields
          nativeInputValueSetter.call(document.getElementById('image_file_name_input'), '');
          nativeInputValueSetter.call(document.getElementById('image_name_input'), '');
          nativeInputValueSetter.call(document.getElementById('image_tags_input'), imageUser);
          nativeInputValueSetter.call(document.getElementById('image_datetime_input'), '');            
          //dispatch searh event on design id field
          var ev2 = new Event('input', { bubbles: true});
          //first trigger events for other empty fields to clear them from the react filter
          document.getElementById('image_file_name_input').dispatchEvent(ev2);
          document.getElementById('image_name_input').dispatchEvent(ev2);
          document.getElementById('image_datetime_input').dispatchEvent(ev2);
          document.getElementById('image_design_id_input').dispatchEvent(ev2);
          //final event trigger to searh for tags only
          document.getElementById('image_tags_input').dispatchEvent(ev2);
      
  }

    /* image table schema */
    const columns = useMemo(
      () => [
          {
              Header: 'File Name',
              accessor: 'image_file_name',
              key: 'image_file_name',
              style: {
                  width: '20%',
                  color: '#212b36',
                  fontWeight: '600'
              },
              Cell: (props) => {
                  
                  let myImage = ''
                  let myImageDisplay = 'block'
                  let myImageDisplayNone = 'none'
                  let fileType = 'image'
                  if(props.cell.value){
                    myImage = props.cell.value
                    //get the key of the last item in the array
                    let myKey = 1;
                    if(myImage.split('.').length > 0){
                      myKey = myImage.split('.').length - 1
                      if(myImage.split('.')[myKey] !== 'png' && myImage.split('.')[myKey] !== 'jpg' && myImage.split('.')[myKey] !== 'jpeg' && myImage.split('.')[myKey] !== 'gif' && myImage.split('.')[myKey] !== 'svg' ){
                        myImageDisplay = "none"
                        myImageDisplayNone = 'block'  
                      }
                      if(myImage.split('.')[myKey] === 'mp4'){
                        fileType = 'video'
                      }
                      if(myImage.split('.')[myKey] === 'pdf'){
                        fileType = 'pdf'
                      }
                    }
                  }else{
                    myImageDisplay = 'none'
                    myImageDisplayNone = 'block'
                  }

                 
                  return (
                    <div>
                        { fileType === 'pdf' ?
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px'}}>
                              <PictureAsPdfIcon className="greyHover" data-index={props.row.index} data-id={props.row.original.image_design_id} onClick={copyToClipboardData} style={{marginTop: 'auto', marginBottom: 'auto', cursor: 'pointer'}}  />
                            </div>
                        : myImageDisplay === 'block' ?
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px'}}>
                                  <img alt="preview" src={process.env.REACT_APP_CACHE_URL + props.cell.value + '?auto=compress&fm=webp&h=300'} data-index={props.row.index} data-id={props.row.original.image_design_id} onClick={gridType === 'grid' ? openModal : selectImage} style={{display: myImageDisplay, marginTop: 'auto', marginBottom: 'auto'}} />
                            </div>
                        : fileType === 'video' ?
                          <div className="videoThumb" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px'}}>
                              <VideoPlayer videoUrl={'https://' + process.env.REACT_APP_BUCKET + '.s3.us-east-2.amazonaws.com/' + props.cell.value} snapshotAt={10} style={{marginTop: 'auto', marginBottom: 'auto'}} />

                          </div>
                        :
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px'}}>
                                <div className="none" data-src={'https://' + process.env.REACT_APP_BUCKET + '.s3.us-east-2.amazonaws.com/' + props.cell.value} onClick={copyToClipboardData}  style={{display: myImageDisplayNone, marginTop: 'auto', marginBottom: 'auto'}}>
                                    No preview available 
                                </div>
                            </div>
                        }
                        
                        <div style={{marginTop: '10px', fontSize: '15px', cursor: 'pointer', maxWidth: '280px', wordWrap: 'break-word'}} data-src={'https://' + process.env.REACT_APP_BUCKET + '.s3.us-east-2.amazonaws.com/' + props.cell.value} onClick={copyToClipboardData} >{props.cell.value}</div>
                    </div>
                    
                  );
              },
          },
          {   
              Header: 'Design ID',
              accessor: 'image_design_id',
              key: 'image_design_id',
              style: {
                  width: '10%',
                  color: '#212b36',
                  fontWeight: '600'
              },
              Cell: (props) => {
                  return (
                      <div style={{cursor: 'pointer', color: '#2DB7F0'}} data-design={props.cell.value} onClick={setDesignIdFilter}>Design ID - {props.cell.value}</div>
                  );
              },
          },
          {
              Header: 'Keywords/Name',
              accessor: 'image_name',
              key: 'image_name',
              style: {
                  width: '25%',
                  color: '#212b36',
                  fontWeight: '600'
              },
              Cell: (props) => {
                  return (
                      <div style={{display: 'none'}}>{props.cell.value}</div>
                  );
              },
          },
          {
            Header: 'User (first.last)',
            accessor: 'image_tags',
            key: 'image_tags',
            style: {
                width: '10%',
                color: '#212b36',
                fontWeight: '600'
            },
            Cell: (props) => {
                return (
                    <div style={{display: 'none'}}> </div>
                );
            },
        },
          {
              Header: 'Date/Time',
              accessor: 'image_datetime',
              key: 'image_datetime',
              style: {
                  width: '10%',
                  color: '#212b36',
                  fontWeight: '600'
              },
              Cell: (props) => {
                  return (
                      <div style={{display: 'none'}}> </div>
                  );
              },
          },
          
      ],
      []
  )

    /*callback function for table child to update the query state - added to onchange property of the tablecontainer component below */
  const changeQuery = (text, key, numrows) => {
      //set the column and setQuery hook value.  this will trigger the useEffect function below, which will requery the BQ table if needed.
      setCurColumn(key);
      //store all columns and query values that are currently being used in the filter
      //if text is empty, remove this key and value from qcolumn and qvalue (use indexOf to find the index in the array, and remove the same index from both)
      //if the text is not empty, add/update values in qcolumn and qvalue
      let myCols = qcolumn;
      let getIndex = myCols.indexOf(key);
      if(getIndex > -1){
        if(text === 'null'){
          // when datepicker is cleared, clear state
          // setStartDate(null)
        }
          //this column exists in qcolumn.  we should update its' value in qvalue, or remove it if the value is empty
          if(text !== 'null' || text.length > 0){
              let myVals = qvalue;
              myVals[getIndex] = text;
              setQvalue(myVals);
          }else{
              //remove this column and value from the arrays
              let myVals = qvalue;
              myVals.splice(getIndex, 1);
              setQvalue(myVals);
              let myCols= qcolumn;
              myCols.splice(getIndex, 1);
              setQcolumn(myCols);
          }
      }else{
          //this column does not exist in qcolumn.  add to both qcolumn and qvalue
          myCols.push(key);
          setQcolumn(myCols);
          let myVals = qvalue;
          myVals.push(text);
          setQvalue(myVals);

      }
      
      setRowTotalFilter(numrows);
      //set the query to trigger useEffect below
      setQuery(text);
  };
  

  /* handles image grid filter */
  useEffect(() => {
    console.log('useEffect')
    const doFetch = async () => {
      //on the initial page load, get the RDS datasets needed for the frontend
      setIsLoading(true)
      if(init === true){
          setInit(false) 
          if(designId){
            //this is the initial load.  call setDesignFilter to filter by the default design id
            //set the value of the design id input field

            var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
            nativeInputValueSetter.call(document.getElementById('image_design_id_input'), designId);
            //clear the values of all other input fields
            nativeInputValueSetter.call(document.getElementById('image_file_name_input'), '');
            nativeInputValueSetter.call(document.getElementById('image_name_input'), '');
            nativeInputValueSetter.call(document.getElementById('image_tags_input'), '');
            nativeInputValueSetter.call(document.getElementById('image_datetime_input'), '');            
            //dispatch searh event on design id field
            var ev2 = new Event('input', { bubbles: true});
            //first trigger events for other empty fields to clear them from the react filter
            document.getElementById('image_file_name_input').dispatchEvent(ev2);
            document.getElementById('image_name_input').dispatchEvent(ev2);
            document.getElementById('image_tags_input').dispatchEvent(ev2);
            document.getElementById('image_datetime_input').dispatchEvent(ev2);
            //final event trigger to searh for design id only
            document.getElementById('image_design_id_input').dispatchEvent(ev2);

          }
          
      }
      
      var siteUrl = ''; //set siteUrl if declared (for local testing)
      if(process.env.REACT_APP_API_HOST){
          siteUrl = process.env.REACT_APP_API_HOST;
      }
      if (debouncedSearchTerm) {
          //only query DB again if the current query does not begins with the value of the stored (previous query isSearching), and 
          //force query if more than one table is being filtered.
          var moreOptions = {
              'query': query,
              'columns': qcolumn,
              'values': qvalue
          };
          setRowTotal('...')
          handleCountUpdate({value: '...'})
          
          setIsLoading(true)
          setRowTotal('...')
          //requery table. queries do not match
          setIsSearching(query)
          const response = await axios.post(siteUrl + '/api/getRecords', moreOptions, { withCredentials: true })
          const records = response.data
          setData(records)
          //set the filter to filter the local results now that the data has been reset to the query
          setIsLoading(false)
          let result = await axios.post(siteUrl + '/api/getRecordsCount', moreOptions, { withCredentials: true })
          //update the rowTotal count to the number of rows
          const rowsTotal = result.data[0].count
          console.log('rowsTotal: ' + rowsTotal)
          setRowTotal(rowsTotal)
          //count the number of pages in the current results
          if(rowsTotal > 60){
              //there are more than 60 items.  We should add/update pagenation
              var pageNumbers = Math.ceil(rowsTotal / 60);
              
          }
          handleCountUpdate({value: rowsTotal}) 
           
      }else{
          setQuery([]);
      }
      
    }
    doFetch()
  }, [debouncedSearchTerm])


  /* callback to the parent app */
  const handleQuery = (e, key, numrows) => {
    // if(key === 'image_datetime'){
    //   setStartDate(e.target.value)
    // }
    console.log('e.target.value: ' + e.target.value)
    changeQuery(e.target.value, key, numrows);

  }

   
  /* load all records from filter (bypassing 500 record limit) */
  const getAllRecords = () => {
    //need to add functionality here - simililar to PIM
    //callback function to remove the sql limit from the query
    setIsLoading(true)
    var siteUrl = ''; //set siteUrl if declared (for local testing)
      if(process.env.REACT_APP_API_HOST){
          siteUrl = process.env.REACT_APP_API_HOST;
      }
    const doFetch = async () => {
        //console.log('3 levels in hooked :)')
        var moreOptions = {
            'query': query,
            'perPage': 100000,
            'columns': qcolumn,
            'values': qvalue
        };
        const response = await axios.post(siteUrl + '/api/getRecords', moreOptions, { withCredentials: true })
        const records = response.data
        console.log(JSON.stringify(records))
        setData(records)
        //set the filter to filter the local results now that the data has been reset to the query
        setIsLoading(false)
    }
    doFetch()
  }

  
  /* default filter for data grid */
  const DefaultColumnFilter = ({
      column: {
        filterValue,
        setFilter,
        setQuery,
        setData,
        preFilteredRows: { length },
        key
      },
      columns,
      rows
    }) => {

      return (
        <div>
          {key !== 'image_datetime' ?
            <Input className="searchFields" id={key + '_input'} 
              /*callback function above from props to update the state in the main app (query state) */  
              style={{paddingTop: '10px', paddingBottom: '10px', width: '100%', paddingRight: '0px', marginLeft: '0px', paddingLeft: '2px', border: '1px solid #dee2e6'}}
              onChange={e => {
                setFilter(e.target.value); //we need to update the total records label once the filter is complete.
                handleQuery(e, key, rows.length) 
              }}  
            />
          :
            <div >
              {key === 'image_datetime' ?
                  <div>
                      <DatePicker id="image_datetime_input" isClearable selected={startDate} onChange={date => {handleQuery({'target':{'value':date}}, key, rows.length); setStartDate(date)}}  />
                  </div>
              :
                  null
          
              }


            </div>
          }
        </div>
        
      )
  }

  


    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, selectedRowIds },
      selectedFlatRows,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 60 },
        Footer: <hr />,
        defaultColumn: { Filter: DefaultColumnFilter },
        manualPagination: false,
        showPagination: true,
        showPaginationTop: false,
        showPaginationBottom: true,  
      },
      useFilters,
      useSortBy,
      usePagination,
      useRowSelect

    )
  
    const generateSortingIndicator = column => {
      return column.isSorted ? (column.isSortedDesc ?  <ArrowDownwardIcon className="sortArrows" /> : <ArrowUpwardIcon className="sortArrows" />) : ""
    }

    const getSelectedRows = selectedFlatRows.map(
        d => d.original
    ).length

    const firstPageRows = rows.slice(0, 60)

    const customStyles = {
        marginTop: '50px'
    };

    /* copy to clipboard from preview pane */
    // With release/v1.1.3-copyToClipboard the modal has switched to copyToClipboardData and no longer uses this
    const copyToClipboard = (e) => {
          e.preventDefault();
          e.target.select();
          document.execCommand('copy');
          e.target.focus();
          //set timer to hide message in 3 seconds
          setCopySuccess('Copied!!')
          setTimeout(() => setCopySuccess('') , 3000);

    };

    const handleLoader = (e) => {
      setIsLoading(e)
    }

    
    


    /* copy to clipboard from data grid - replace all copy func with this logic in future */
    const copyToClipboardData = (e) => {
      e.preventDefault();
      const el = document.createElement('textarea');
      el.value = e.target.dataset.src;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      //set timer to hide message in 3 seconds
      setCopySuccess('Copied!!')
      setTimeout(() => setCopySuccess('') , 3000);

  };


  return (
      <>
      {copySuccess ? 
          <div className="copiedAlert3"><FileCopyIcon className="buttonIcon" />{copySuccess}</div>
      : null }
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Edit Records"
          >


          
          <div className="buttonsWrapper">
              
              {previewImage && init === false ? 
                <div className="copyBackground"
                      data-src={previewImage} 
                      onClick={copyToClipboardData} >
                      <div data-src={previewImage}  style={{width: '100%'}}>{previewImage}</div>
                      <div className="copiedAlert2"></div>
                </div>
              : null }


        
                  <div style={{marginLeft: 'auto', display: 'flex'}}>

                    

                    {permissions.delete_image ?
                      <div>
                        {permissions.delete_image === 2 ? 
                          <button onClick={(e) => confirmDelete()} className="actionButton cancel">
                              <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                <DeleteForeverIcon className="buttonIcon"></DeleteForeverIcon>
                              </div>
                              <div style={{marginTop: 'auto', marginBottom: 'auto', width: '100%', alignSelf: 'flex-start', minWidth: '100px'}}>
                                delete image
                              </div>
                          </button>
                        : 
                          
                          <button onClick={e => e.preventDefault} className="actionButton greyButton" style={{color: '#adb6b7', marginRight: '10px'}}><LockIcon className="buttonIcon"></LockIcon>delete image</button>
                         
                        }
                      </div>
                    : 
                        
                      <button onClick={e => e.preventDefault} className="actionButton greyButton" style={{color: '#adb6b7', marginRight: '10px'}}><LockIcon className="buttonIcon"></LockIcon>delete image</button>
                     
                    }

                    <button onClick={closeModal} className="actionButton close">
                      <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                        <HighlightOffIcon className="buttonIcon"></HighlightOffIcon>
                      </div>
                      <div style={{marginTop: 'auto', marginBottom: 'auto'}}>  
                        close
                      </div>

                    </button>
                  </div>
              



          </div>


          
          
              <div style={{display: 'flex', marginTop: '20px'}}>
                    <div className="largePreview" >
                        {previewImage.split('.')[previewImage.split('.').length - 1] !== 'png' && previewImage.split('.')[previewImage.split('.').length - 1] !== 'jpg' && previewImage.split('.')[previewImage.split('.').length - 1] !== 'jpeg' && previewImage.split('.')[previewImage.split('.').length - 1] !== 'gif' && previewImage.split('.')[previewImage.split('.').length - 1] !== 'svg'  ?
                            <div>No Preview Available</div>
                        : <div style={{width: '100%', textAlign: 'center'}}><img alt="preview" src={previewImage} className="previewImage" onLoad={() => 
                        

                                setTimeout(() => {
                                  setIsLoading(false)
                                }, 500)
                              
                              
                              } />

                              <div style={{display:'block',  textAlign:'center'}}>
                                {data[previewIndex] ? 
                                  <div>
                                    <strong>Uploaded At: </strong> {data[previewIndex].image_datetime}
                                    <br/>
                                    <strong>Image Name: </strong> {data[previewIndex].image_name}
                                    <br/>
                                    <strong>Image Tags: </strong> {data[previewIndex].image_tags}
                                  </div>
                                : null }
                              </div>


                          </div>
                        }
                    </div>  
                </div>
           
          


                {confirmationOpen  ?
                    <div id="myModal" class="modal open" style={{zIndex: "12000000000"}}>
                        <div className="modal-content">
                            <div style={{fontSize: '18px', padding: '10px', textAlign: 'left', color: 'rgb(1, 56, 120)', fontWeight: 'bold', marginBottom: '20px'}}>
                                Are you sure you want to delete this image? 
                                <p style={{color: 'red', fontWeight: '300'}}>This action cannot be undone!!</p>
                            </div>
                            
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <button onClick={(e) => setConfirmationOpen('')} className="greyButton" style={{padding: '10px', marginTop: '5px', marginRight: '10px', display: 'flex', cursor: 'pointer'}}><span style={{marginTop: 'auto', marginBottom: 'auto'}}><CancelIcon className="buttonIcon"></CancelIcon></span><span style={{marginTop: 'auto', marginBottom: 'auto'}}>CANCEL</span></button>
                                <div style={{display: 'flex'}}>
                                    <button data-type="archive" id="buttonArchive" onClick={(e) => deleteImage()}   className="actionButton"  style={{padding: '10px', display: 'flex', cursor: 'pointer'}}><span style={{marginTop: 'auto', marginBottom: 'auto'}}><CheckCircle className="buttonIcon"></CheckCircle></span><span style={{marginTop: 'auto', marginBottom: 'auto'}}>YES</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                : <div></div> }

       


        </Modal>

        { isLoading ? <div className="snowflake"><img alt="Snow Commerce" className="App-logo" src="/snow-mark.png" /></div> :  
                        null 
                    }
      <div style={{display: 'flex', float: 'right', marginTop: '-80px'}}>
        <button  className={gridType === "picker" ? "hideMe" : "clearFilter"} onClick={setTagsFilter} ><PhotoLibraryIcon className="buttonIcon"></PhotoLibraryIcon>my uploads</button>
        <button  className={gridType === "picker" ? "hideMe" : "clearFilter"} onClick={setDesignIdFilter} ><HighlightOffIcon className="buttonIcon"></HighlightOffIcon>clear filters</button>
      </div>

      <table {...getTableProps()} style={{border: 'none', width: '100%'}} className={gridType === "grid" ? "total-card-container" : "nonenone"}>
        <thead>
        
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                //<th {...column.getHeaderProps()} style={{border: 'none'}}>{column.render('Header')}</th>
                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{padding: '5px'}}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </div>
                  
                </th>
              ))}
            </tr> 
          ))}

          {headerGroups.map((headerGroup, key) => (
            <tr key={key + '_headWrap'}>
              {headerGroup.headers.map((column, index) => (
                //<th {...column.getHeaderProps()} style={{border: 'none'}}>{column.render('Header')}</th>
                <th key={index + '_colHead'} style={column.style}><div style={{ padding: '0px'}}>
                    {column.canFilter && column.render("Filter") }
                  </div>
                </th>
              ))}
            </tr>
          ))}

          
        </thead>
        </table>


        <div {...getTableBodyProps()} className="parentGallery">
          {page.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="childGallery grid" >
                {row.cells.map((cell) => {
                  return <div {...cell.getCellProps()} style={{
                    padding: '5px',
                    border: 'none'
                    
                    }}>{cell.render('Cell')}</div>;
                })}
              </div>
            );
          })}
        </div>
        
      

        

        {data.length === 0 && isLoading === false ? 

            <div className="total-card-container noResults">0 records were found.  Narrow your filters and try again!</div>
        : null }
      
  <div className="pagination">
      <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
      {'<<'}
      </button>{' '}
      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
      {'<'}
      </button>{' '}
      <button onClick={() => nextPage()} disabled={!canNextPage}>
      {'>'}
      </button>{' '}
      <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
      {'>>'}
      </button>{' '}
      <span>
      Page{' '}
      <strong>
          {pageIndex + 1} of {pageOptions.length}
      </strong>{' '}
      </span>
      <span>
      | Go to page:{' '}
      <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={e => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0
          gotoPage(page)
          }}
          style={{ width: '100px' }}
      />
      </span>{' '}
      <select
      value={pageSize}
      onChange={e => {
          setPageSize(Number(e.target.value))
      }}
      >
      {[60, 100, 200].map(pageSize => (
          <option key={pageSize} value={pageSize}>
          Show {pageSize}
          </option>
      ))}
      </select>
  </div>

  { data.length === 500 && rowTotal > 500 ?
    <div className="totalCount">
      500 of {rowTotal} results are displayed.  <a onClick={getAllRecords} style={{color: '#006fbb', cursor: 'pointer'}} >Click here</a> to load all {rowTotal}.
    </div>
  : null }
  { data.length === 200 && rowTotal > 200 ?
    <div className="totalCount">The most recent 200 records are displayed.  Use the filters above to return more results.</div>
  : null }
  
  

  <pre className="codeTemp">
      <code>
      {JSON.stringify(
          {
          pageIndex,
          pageSize,
          pageCount,
          canNextPage,
          canPreviousPage,
          },
          null,
          2
      )}
      </code>
  </pre>
  <br />
  <div className="codeTemp">
    <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
            <pre>
                <code>
                {JSON.stringify(
                    {
                    selectedRowIds: selectedRowIds,
                    'selectedFlatRows[].original': selectedFlatRows.map(
                        d => d.original
                    ),
                    },
                    null,
                    2
                )}
                </code>
            </pre>
            
            {getSelectedRows}
  </div>      
  
  
</>
     
      
      
  )

}



export default TableContainer;


